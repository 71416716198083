import React from "react";
import Helmet from "react-helmet";

import Layout from "components/Layout";
import Container from "components/Container";
import AnimatedText from "components/AnimatedText";

const About = () => {
  return (
    <Layout pageName='about'>
      <Helmet>
        <title>About</title>
      </Helmet>
      <Container>
        <div className='about-wrapper'>
          <div className='about-image'>
            <img src='/images/profile.jpg' alt='Photo of myself' />
          </div>
          <div className='about-content'>
            <AnimatedText small>Adventurous designer,</AnimatedText>

            <AnimatedText delay={0.1} small>
              Apprentice developer,
            </AnimatedText>

            <AnimatedText delay={0.2} small>
              Half-time music producer!
            </AnimatedText>

            <p>
              Hi 🙌 my name is Antoine I'm a 25 years old designer based in
              Paris, welcome to my portfolio and visual playground! I love to
              design and code all sort of things that are both usable and
              aesthetics.
            </p>
            <a href='mailto: antoine.venco@gmail.com'>
              Ask a question or just say hi!
            </a>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default About;
